import { Modal } from "../../../../components/Modal";
import { FormTextInput } from "../../../../components/form/FormTextInput";
import { Form, Formik } from "formik";
import { Button, ButtonType } from "../../../../components/buttons/Button";
import { useNodeApi } from "../../../../shared/useNodeApi";
import { useQuery } from "@tanstack/react-query";
import { Loader } from "../../../../components/Loader";
import { useRef, useState } from "react";
import {
  GetIssuesForTestRun200ResponseInner,
  TestRunIssue,
} from "../../../../../generated/syncroom-api/src";
import { IssueSelectionCheckbox } from "./IssueSelectionCheckbox";
import { FaSearch } from "react-icons/fa";
import { ResponseErrorMessage } from "../../../../components/ResponseErrorMessage";
import { SelectOption } from "../../../../components/select/SelectWrapper";
import { FormSelectInput } from "../../../../components/form/FormSelectInput";

export type FormValues = {
  entity: string;
  search: string;
  selectedIssues: GetIssuesForTestRun200ResponseInner[];
};

type Props = {
  sourceNodeId: string;
  connectionId: string;
  versionId: string;
  onClose: () => void;
  issueSelection: TestRunIssue[];
  startTestRun: (issues: TestRunIssue[]) => void;
};

export const IssueSelectionModal = ({
  sourceNodeId,
  connectionId,
  versionId,
  onClose,
  issueSelection,
  startTestRun,
}: Props) => {
  const [search, setSearch] = useState<string>();
  const [entityType, setEntityType] = useState<string>();
  const timerRef = useRef<ReturnType<typeof setTimeout>>();

  const api = useNodeApi();
  const issuesQuery = useQuery({
    queryKey: [
      "nodes",
      sourceNodeId,
      "connections",
      connectionId,
      "versions",
      versionId,
      "testrun",
      "search",
      entityType,
      search,
    ],
    queryFn: () =>
      api.getIssuesForTestRun({
        nodeId: sourceNodeId,
        connectionId,
        versionId,
        search: search!,
        entityType: entityType!,
      }),
    enabled:
      search !== undefined && search.length > 0 && entityType !== undefined,
  });

  const entityTypesRequest = useQuery({
    queryKey: ["triggers", "entityTypes"],
    queryFn: () =>
      api.getEntityTypesForConnection({
        nodeId: sourceNodeId,
        connectionId,
      }),
  });

  if (entityTypesRequest.isPending) {
    return <Loader />;
  }
  if (entityTypesRequest.isError) {
    return (
      <ResponseErrorMessage error={entityTypesRequest.error}>
        Error fetching entity types
      </ResponseErrorMessage>
    );
  }
  const entityTypes: SelectOption[] = entityTypesRequest.data.map((entity) => ({
    value: entity,
    label: entity,
  }));

  if (!entityType && entityTypes[0].value !== entityType)
    setEntityType(entityTypes[0].value);

  const foundIssues = issuesQuery.data;

  const filterFoundIssues = (selectedIssues: TestRunIssue[]) =>
    foundIssues?.filter((foundIssue) => !selectedIssues.includes(foundIssue));

  return (
    <Formik
      enableReinitialize={false}
      initialValues={
        {
          entity: entityTypes[0].value,
          search: search || "",
          selectedIssues: issueSelection,
        } as FormValues
      }
      onSubmit={(values) => startTestRun(values.selectedIssues)}
    >
      {({ values, handleChange }) => {
        return (
          <Form noValidate className="h-full">
            <Modal
              title="Select Entities"
              toggleModal={() => onClose()}
              buttons={
                <>
                  <Button onClick={() => onClose()} type={ButtonType.SECONDARY}>
                    Cancel
                  </Button>
                  <Button
                    buttonType="submit"
                    disabled={values.selectedIssues.length === 0}
                  >
                    Start Test Run
                  </Button>
                </>
              }
            >
              <div className="space-y-5">
                <FormSelectInput
                  label="Entity type"
                  name="entity"
                  options={entityTypes}
                  onChange={(e) => {
                    setEntityType(e!.value);
                  }}
                />
                <FormTextInput
                  label="Entities"
                  sublabel="Search for entities on name or number."
                  name="search"
                  onChange={(e) => {
                    handleChange(e);
                    if (timerRef) clearTimeout(timerRef.current);
                    timerRef.current = setTimeout(async () => {
                      setSearch(e.target.value);
                    }, 1000);
                  }}
                  iconLeft={<FaSearch />}
                ></FormTextInput>
              </div>

              {(search !== undefined || issueSelection.length > 0) && (
                <div className="mt-5 max-h-72 overflow-y-auto rounded-lg border">
                  <div className="flex flex-col border-b px-4 pb-4 pt-2.5">
                    <p className="text-base font-bold leading-relaxed">
                      Selected entities
                    </p>
                    {values.selectedIssues.length === 0 && (
                      <p className="text-neutral-greyer_grey text-sm font-medium leading-normal">
                        No entities selected. Select at least one entity to
                        start Test Run.
                      </p>
                    )}
                    {values.selectedIssues.length > 0 &&
                      values.selectedIssues.map((issue) => (
                        <div
                          className="flex items-center border-b px-4 py-3"
                          key={`selected-${issue.issueUrn}`}
                        >
                          <IssueSelectionCheckbox issue={issue} />
                        </div>
                      ))}
                  </div>

                  <div className="flex flex-col border-b px-4 pb-4 pt-2.5">
                    <p className="text-base font-bold leading-relaxed">
                      Select entities
                    </p>
                    {issuesQuery.isLoading && <Loader />}
                    {foundIssues &&
                      foundIssues.length > 0 &&
                      filterFoundIssues(values.selectedIssues)!.map(
                        (foundIssue) => (
                          <div
                            className="flex items-center border-b px-4 py-3"
                            key={`select-${foundIssue.issueUrn}`}
                          >
                            <IssueSelectionCheckbox issue={foundIssue} />
                          </div>
                        ),
                      )}
                    {(!foundIssues ||
                      filterFoundIssues(values.selectedIssues)!.length ===
                        0) && (
                      <p className="text-neutral-greyer_grey text-sm font-medium leading-normal">
                        Found no (more) entities matching your search query.
                        Please try a different search query.
                      </p>
                    )}
                  </div>
                </div>
              )}
            </Modal>
          </Form>
        );
      }}
    </Formik>
  );
};
