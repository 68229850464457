/* tslint:disable */
/* eslint-disable */
/**
 * SyncRoom Api
 * SyncRoom API
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@example.net
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ErrorListInner } from './ErrorListInner';
import {
    ErrorListInnerFromJSON,
    ErrorListInnerFromJSONTyped,
    ErrorListInnerToJSON,
} from './ErrorListInner';
import type { TestRunIssue } from './TestRunIssue';
import {
    TestRunIssueFromJSON,
    TestRunIssueFromJSONTyped,
    TestRunIssueToJSON,
} from './TestRunIssue';

/**
 * 
 * @export
 * @interface StartTestRunOutgoing200ResponseInner
 */
export interface StartTestRunOutgoing200ResponseInner {
    /**
     * 
     * @type {TestRunIssue}
     * @memberof StartTestRunOutgoing200ResponseInner
     */
    issue: TestRunIssue;
    /**
     * A representation of a Replica as a string escaped JSON object
     * @type {string}
     * @memberof StartTestRunOutgoing200ResponseInner
     */
    replica?: string;
    /**
     * A list of error messages as a result of an action
     * @type {Array<ErrorListInner>}
     * @memberof StartTestRunOutgoing200ResponseInner
     */
    errors?: Array<ErrorListInner>;
}

/**
 * Check if a given object implements the StartTestRunOutgoing200ResponseInner interface.
 */
export function instanceOfStartTestRunOutgoing200ResponseInner(value: object): value is StartTestRunOutgoing200ResponseInner {
    if (!('issue' in value) || value['issue'] === undefined) return false;
    return true;
}

export function StartTestRunOutgoing200ResponseInnerFromJSON(json: any): StartTestRunOutgoing200ResponseInner {
    return StartTestRunOutgoing200ResponseInnerFromJSONTyped(json, false);
}

export function StartTestRunOutgoing200ResponseInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): StartTestRunOutgoing200ResponseInner {
    if (json == null) {
        return json;
    }
    return {
        
        'issue': TestRunIssueFromJSON(json['issue']),
        'replica': json['replica'] == null ? undefined : json['replica'],
        'errors': json['errors'] == null ? undefined : ((json['errors'] as Array<any>).map(ErrorListInnerFromJSON)),
    };
}

export function StartTestRunOutgoing200ResponseInnerToJSON(value?: StartTestRunOutgoing200ResponseInner | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'issue': TestRunIssueToJSON(value['issue']),
        'replica': value['replica'],
        'errors': value['errors'] == null ? undefined : ((value['errors'] as Array<any>).map(ErrorListInnerToJSON)),
    };
}

