import { useMutation } from "@tanstack/react-query";
import {
  ConnectionDetail,
  ConnectionVersion,
  ConnectionVersionStatusEnum,
  CreateConnectionVersion201Response,
  ResponseError,
} from "../../../../generated/syncroom-api/src";
import {
  ActionsType,
  ConnectionActionSelect,
  ToggleType,
} from "./ConnectionActionSelect";
import { useNodeApi } from "../../../shared/useNodeApi";
import { ToggleCentrallyManagedModal } from "./ToggleCentrallyManagedModal";
import React, { useState } from "react";
import { ActivateVersionModal } from "./ActivateVersionModal";
import { ConnectionErrorModal } from "./ConnectionErrorModal";
import { RemoveConnectionModal } from "./RemoveConnectionModal";

type Props = {
  nodeId: string;
  connectionDetail: ConnectionDetail;
  selectedVersion?: ConnectionVersion;
  setSelectedVersionId: (versionId: string) => void;
};

export const ConnectionActions = ({
  nodeId,
  connectionDetail,
  selectedVersion,
  setSelectedVersionId,
}: Props) => {
  const [
    isToggleCentrallyManagedModalOpen,
    setIsToggleCentrallyManagedModalOpen,
  ] = useState<boolean>(false);
  const [isActivateVersionModalOpen, setIsActivateVersionModalOpen] =
    useState<boolean>(false);
  const [isRemoveConnectionModalOpen, setIsRemoveConnectionModalOpen] =
    useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const api = useNodeApi();
  const createVersionMutate = useMutation<
    CreateConnectionVersion201Response,
    ResponseError
  >({
    mutationKey: ["createVersionMutate"],
    mutationFn: () =>
      api.createConnectionVersion({
        nodeId,
        connectionId: connectionDetail.id,
        body: selectedVersion!.id,
      }),
    onSuccess: async (response) => {
      setSelectedVersionId(response.versionId);
    },
  });

  const options: (ActionsType | ToggleType)[] = [
    {
      label: `${selectedVersion?.status === ConnectionVersionStatusEnum.ARCHIVED ? "Restore" : "Add new"} version`,
      buttonAction: () => createVersionMutate.mutate(),
      isDisabled: !connectionDetail.centrallyManaged,
      isToggle: false,
    },
    {
      label: "Publish version",
      buttonAction: () => setIsActivateVersionModalOpen(true),
      isDisabled:
        !connectionDetail.centrallyManaged ||
        selectedVersion?.status !== ConnectionVersionStatusEnum.DRAFT,
      isToggle: false,
    },
    {
      label: `Admin ${connectionDetail.sourceNode.customName}`,
      buttonAction: () => window.open(connectionDetail.sourceNode.adminUrl),
      isDisabled: false,
      isToggle: false,
    },
    {
      label: `Admin ${connectionDetail.targetNode.customName}`,
      buttonAction: () => window.open(connectionDetail.targetNode.adminUrl),
      isDisabled: false,
      isToggle: false,
    },
    {
      label: "Manage connection in SyncRoom",
      isManaged: connectionDetail.centrallyManaged,
      buttonAction: () => setIsToggleCentrallyManagedModalOpen(true),
      isDisabled: false,
      isToggle: true,
    },
    {
      label: "Remove connection from network",
      buttonAction: () => {
        setIsRemoveConnectionModalOpen(true);
      },
      isDisabled: false,
      isToggle: false,
    },
    {
      label: "See errors",
      buttonAction: () => setIsErrorModalOpen(true),
      isDisabled:
        connectionDetail.totalErrors === 0 &&
        !connectionDetail.errorsOnOppositeConnection,
      isToggle: false,
    },
  ];

  return (
    <>
      {isErrorModalOpen && (
        <ConnectionErrorModal
          setIsModalOpen={setIsErrorModalOpen}
          onClose={() => setIsErrorModalOpen(false)}
          nodeId={nodeId}
          connectionId={connectionDetail.id}
        />
      )}
      {isToggleCentrallyManagedModalOpen && (
        <ToggleCentrallyManagedModal
          setIsModalOpen={setIsToggleCentrallyManagedModalOpen}
          onClose={() => setIsToggleCentrallyManagedModalOpen(false)}
          nodeId={nodeId}
          connectionId={connectionDetail.id}
          centrallyManaged={connectionDetail.centrallyManaged}
          sourceNodeName={connectionDetail.sourceNode.customName}
          targetNodeName={connectionDetail.targetNode.customName}
        />
      )}
      {connectionDetail.activeVersionId && isActivateVersionModalOpen && (
        <ActivateVersionModal
          setIsModalOpen={setIsActivateVersionModalOpen}
          onClose={() => setIsActivateVersionModalOpen(false)}
          nodeId={nodeId}
          connectionId={connectionDetail.id}
          versionId={selectedVersion!.id}
        />
      )}
      {isRemoveConnectionModalOpen && (
        <RemoveConnectionModal
          setIsModalOpen={setIsRemoveConnectionModalOpen}
          onClose={() => setIsRemoveConnectionModalOpen(false)}
          connectionId={connectionDetail.id}
          nodeId={nodeId}
          sourceNodeName={connectionDetail.sourceNode.customName}
          targetNodeName={connectionDetail.targetNode.customName}
        />
      )}
      <ConnectionActionSelect options={options} />
    </>
  );
};
