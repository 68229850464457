/* tslint:disable */
/* eslint-disable */
/**
 * SyncRoom Api
 * SyncRoom API
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@example.net
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { TestRunIssue } from './TestRunIssue';
import {
    TestRunIssueFromJSON,
    TestRunIssueFromJSONTyped,
    TestRunIssueToJSON,
} from './TestRunIssue';

/**
 * 
 * @export
 * @interface StartTestRunIncomingRequestInner
 */
export interface StartTestRunIncomingRequestInner {
    /**
     * 
     * @type {TestRunIssue}
     * @memberof StartTestRunIncomingRequestInner
     */
    issue: TestRunIssue;
    /**
     * A representation of a Replica as a string escaped JSON object
     * @type {string}
     * @memberof StartTestRunIncomingRequestInner
     */
    replica: string;
}

/**
 * Check if a given object implements the StartTestRunIncomingRequestInner interface.
 */
export function instanceOfStartTestRunIncomingRequestInner(value: object): value is StartTestRunIncomingRequestInner {
    if (!('issue' in value) || value['issue'] === undefined) return false;
    if (!('replica' in value) || value['replica'] === undefined) return false;
    return true;
}

export function StartTestRunIncomingRequestInnerFromJSON(json: any): StartTestRunIncomingRequestInner {
    return StartTestRunIncomingRequestInnerFromJSONTyped(json, false);
}

export function StartTestRunIncomingRequestInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): StartTestRunIncomingRequestInner {
    if (json == null) {
        return json;
    }
    return {
        
        'issue': TestRunIssueFromJSON(json['issue']),
        'replica': json['replica'],
    };
}

export function StartTestRunIncomingRequestInnerToJSON(value?: StartTestRunIncomingRequestInner | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'issue': TestRunIssueToJSON(value['issue']),
        'replica': value['replica'],
    };
}

