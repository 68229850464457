/* tslint:disable */
/* eslint-disable */
/**
 * SyncRoom Api
 * SyncRoom API
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@example.net
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ErrorListInner } from './ErrorListInner';
import {
    ErrorListInnerFromJSON,
    ErrorListInnerFromJSONTyped,
    ErrorListInnerToJSON,
} from './ErrorListInner';

/**
 * 
 * @export
 * @interface StartTestRunIncoming200ResponseInner
 */
export interface StartTestRunIncoming200ResponseInner {
    /**
     * 
     * @type {string}
     * @memberof StartTestRunIncoming200ResponseInner
     */
    sourceIssueUrn: string;
    /**
     * 
     * @type {string}
     * @memberof StartTestRunIncoming200ResponseInner
     */
    targetIssueUrn?: string;
    /**
     * A representation of a Replica as a string escaped JSON object
     * @type {string}
     * @memberof StartTestRunIncoming200ResponseInner
     */
    replica?: string;
    /**
     * A list of error messages as a result of an action
     * @type {Array<ErrorListInner>}
     * @memberof StartTestRunIncoming200ResponseInner
     */
    errors?: Array<ErrorListInner>;
}

/**
 * Check if a given object implements the StartTestRunIncoming200ResponseInner interface.
 */
export function instanceOfStartTestRunIncoming200ResponseInner(value: object): value is StartTestRunIncoming200ResponseInner {
    if (!('sourceIssueUrn' in value) || value['sourceIssueUrn'] === undefined) return false;
    return true;
}

export function StartTestRunIncoming200ResponseInnerFromJSON(json: any): StartTestRunIncoming200ResponseInner {
    return StartTestRunIncoming200ResponseInnerFromJSONTyped(json, false);
}

export function StartTestRunIncoming200ResponseInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): StartTestRunIncoming200ResponseInner {
    if (json == null) {
        return json;
    }
    return {
        
        'sourceIssueUrn': json['sourceIssueUrn'],
        'targetIssueUrn': json['targetIssueUrn'] == null ? undefined : json['targetIssueUrn'],
        'replica': json['replica'] == null ? undefined : json['replica'],
        'errors': json['errors'] == null ? undefined : ((json['errors'] as Array<any>).map(ErrorListInnerFromJSON)),
    };
}

export function StartTestRunIncoming200ResponseInnerToJSON(value?: StartTestRunIncoming200ResponseInner | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'sourceIssueUrn': value['sourceIssueUrn'],
        'targetIssueUrn': value['targetIssueUrn'],
        'replica': value['replica'],
        'errors': value['errors'] == null ? undefined : ((value['errors'] as Array<any>).map(ErrorListInnerToJSON)),
    };
}

