import { useEffect, useRef, useState } from "react";
import { Node } from "../../../generated/syncroom-api/src";
import Graph, {
  Edge as GraphEdge,
  GraphData,
  GraphEvents,
  Node as GraphNode,
  Options,
} from "react-vis-graph-wrapper";
import "./styles/NetworkGraphic.css";
import { getNodeImage } from "../../images/svgElements";

type Props = {
  nodes: Node[];
  selectedNodeId: string | null;
  filteredTags?: string[];
  onSelectNode: (event: any) => void;
};

export const NetworkGraphic = ({
  nodes,
  selectedNodeId,
  filteredTags,
  onSelectNode,
}: Props) => {
  const [graphData, setGraphData] = useState<GraphData>({
    nodes: [],
    edges: [],
  });

  const mouseState = useRef({
    inCanvas: false,
    dragging: false,
  });

  useEffect(() => {
    const canvas = document.querySelector("canvas");

    if (canvas === null) {
      return;
    }

    const mouseEnter = () => {
      mouseState.current.inCanvas = true;
      if (mouseState.current.dragging) {
        document.body.style.cursor = "grabbing";
      } else {
        document.body.style.cursor = "grab";
      }
    };
    const mouseLeave = () => {
      mouseState.current.inCanvas = false;
      if (mouseState.current.dragging) {
        document.body.style.cursor = "grabbing";
      } else {
        document.body.style.cursor = "default";
      }
    };

    canvas.addEventListener("mouseenter", mouseEnter);
    canvas.addEventListener("mouseleave", mouseLeave);

    return () => {
      canvas.removeEventListener("mouseenter", mouseEnter);
      canvas.removeEventListener("mouseleave", mouseLeave);
    };
  }, []);

  if (selectedNodeId === null) selectedNodeId = "";

  useEffect(() => {
    let nodesToRender: Node[];
    if (filteredTags === undefined || filteredTags?.length === 0) {
      nodesToRender = nodes;
    } else {
      nodesToRender = nodes.filter((node) =>
        node.tags.some((tag) => filteredTags.includes(tag)),
      );
    }

    const graphNodes = getGraphNodesFromSyncRoomNodes(
      nodesToRender,
      selectedNodeId,
    );
    const graphEdges = getGraphEdgesFromSyncRoomNodes(nodesToRender);
    setGraphData({ nodes: graphNodes, edges: graphEdges });
  }, [nodes, selectedNodeId, filteredTags]);

  const events: GraphEvents = {
    selectNode: (event: any) => {
      onSelectNode(event);
      document.body.style.cursor = "default";
    },
    hoverNode: () => {
      document.body.style.cursor = "pointer";
    },
    blurNode: () => {
      document.body.style.cursor = "grab";
    },
    dragStart: () => {
      document.body.style.cursor = "grabbing";
      mouseState.current.dragging = true;
    },
    dragEnd: () => {
      mouseState.current.dragging = false;
      if (mouseState.current.inCanvas) {
        document.body.style.cursor = "grab";
      } else {
        document.body.style.cursor = "default";
      }
    },
  };

  return (
    <Graph
      title="NetworkGraphic"
      graph={graphData}
      options={defaultOptions}
      events={events}
      className="h-full rounded-b-lg bg-[#F3F3F7]"
      style={{
        backgroundImage: "radial-gradient(#E8E7EC 2px, transparent 0)",
        backgroundSize: "30px 30px",
      }}
    />
  );
};

const getGraphNodesFromSyncRoomNodes = (
  nodes: Node[],
  selectedNodeId: string,
): GraphNode[] => {
  return nodes.map((node) => ({
    id: node.id,
    label: node.customName,
    shape: "image",
    image: getNodeImage(
      node.type,
      node.status === "ERROR",
      node.id === selectedNodeId,
    ),
    url: node.baseUrl,
    imagePadding: -15, // Negative so the label stays close since there is extra spacing for the error icon
  }));
};

const calculateIncrementalRoundness = (index: number) => {
  if (index === 0) return index;
  else return 0.2 + (index - 1) * 0.1;
};

const getGraphEdgesFromSyncRoomNodes = (nodes: Node[]): GraphEdge[] => {
  const edges: GraphEdge[] = [];

  nodes.forEach((node) => {
    node.connectedTo.forEach((connectedNode, index) => {
      const oppositeEdges = edges.filter(
        (edge) => edge.id === `${connectedNode}-to-${node.id}-${index}`,
      );

      if (oppositeEdges.length === 0) {
        edges.push({
          id: `${node.id}-to-${connectedNode}-${index}`,
          from: node.id,
          to: connectedNode,
          smooth: {
            enabled: true,
            type: index % 2 === 0 ? "curvedCW" : "curvedCCW",
            roundness: calculateIncrementalRoundness(index),
          },
        });
      }
    });
  });

  return edges;
};

const defaultOptions: Options = {
  edges: {
    selectionWidth: 0,
    color: "#BDB9C7",
    width: 1.5,
    arrows: {
      to: {
        enabled: true,
        scaleFactor: 1.15,
      },
      from: {
        enabled: true,
        scaleFactor: 1.15,
      },
    },
    hoverWidth: 0,
    arrowStrikethrough: true,
    // @ts-ignore this is here cause the endPointOffset option is not recognised by typescript but does work if given
    endPointOffset: {
      from: 19,
      to: 19,
    },
  },
  nodes: {
    font: {
      size: 18,
      color: "#95919E",
    },
    size: 28,
    labelHighlightBold: false,
  },
  autoResize: true,
  height: "100%",
  width: "100%",
  interaction: {
    hover: true,
    selectConnectedEdges: false, // Enable hover events
    dragNodes: false,
    dragView: true,
    zoomView: true,
    navigationButtons: true,
  },
  physics: {
    enabled: false,
  },
  layout: {
    hierarchical: {
      direction: "UD",
      nodeSpacing: 200,
      levelSeparation: 180,
    },
  },
};
