import { Button, ButtonSize, ButtonType } from "../../buttons/Button";
import { FaCircleExclamation } from "react-icons/fa6";
import { FaChevronRight, FaPlus } from "react-icons/fa";
import {
  ConnectedNode,
  ConnectedNodeConnection,
  Neighbour,
  Node,
} from "../../../../generated/syncroom-api/src";
import { SetNetworkForConnectedNodeModal } from "../../../nodes/detailPage/subcomponents/SetNetworkForConnectedNodeModal";
import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

type Props = {
  originalNode?: Node;
  connectedNode: ConnectedNode;
  fromNetwork?: boolean;
  connectedNodeConnection: ConnectedNodeConnection;
  networkId?: string;
};

export const NodeListAccordionConnection = ({
  originalNode,
  connectedNode,
  fromNetwork,
  connectedNodeConnection,
  networkId,
}: Props) => {
  const [
    isSetNetworkForConnectedNodeModalOpen,
    setIsSetNetworkForConnectedNodeModalOpen,
  ] = useState(false);
  const [selectedNeighbour, setSelectedNeighbour] = useState<Neighbour>();

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const navigateToConnection = (connectionId: string) => {
    const basePath = `/nodes/${originalNode?.id ? originalNode.id : connectedNode.id!}/connections/${connectionId}`;
    if (networkId) {
      navigate(`/networks/${networkId}${basePath}`);
    } else navigate(basePath);
  };

  const getManagedByText = (managedBySyncRoom?: boolean) => {
    if (typeof managedBySyncRoom === "boolean") {
      return managedBySyncRoom ? "SyncRoom" : "Decentral";
    }
    return "Unmanaged";
  };

  return (
    <>
      {isSetNetworkForConnectedNodeModalOpen && selectedNeighbour && (
        <SetNetworkForConnectedNodeModal
          setIsModalOpen={setIsSetNetworkForConnectedNodeModalOpen}
          nodeName={connectedNode.customName}
          nodeUrl={connectedNode.baseUrl}
          neighbour={selectedNeighbour}
          onSave={async () => {
            setIsSetNetworkForConnectedNodeModalOpen(false);
            await queryClient.invalidateQueries({
              queryKey: ["nodes", originalNode!.id, "allConnectedNodes"],
            });
          }}
          onClose={() => {
            setIsSetNetworkForConnectedNodeModalOpen(false);
          }}
        />
      )}
      <div className="px-18">
        <Button
          type={ButtonType.TEXT_ONLY}
          size={ButtonSize.MEDIUM}
          disabled={!connectedNodeConnection.connectionId}
          iconLeft={
            connectedNodeConnection.inError && (
              <FaCircleExclamation className="text-red-700" />
            )
          }
          iconRight={<FaChevronRight />}
          onClick={() => {
            if (connectedNodeConnection.connectionId)
              navigateToConnection(connectedNodeConnection.connectionId);
          }}
        >
          {fromNetwork
            ? `${connectedNode.customName} to ${connectedNodeConnection.targetNodeCustomName}`
            : `${connectedNodeConnection.targetNodeCustomName} to ${connectedNode.customName}`}
        </Button>
        <div className="text-sm font-medium leading-normal">
          <div
            className={`flex items-center ${connectedNodeConnection.connectionId ? "px-3.5 text-slate-400" : "text-slate-300"}`}
          >
            {connectedNodeConnection.networkName ? (
              connectedNodeConnection.networkName
            ) : (
              <Button
                type={ButtonType.TEXT_ONLY}
                size={ButtonSize.MEDIUM}
                iconLeft={<FaPlus />}
                onClick={() => {
                  setSelectedNeighbour({
                    connectionName: connectedNodeConnection.connectionName,
                    url: originalNode!.baseUrl,
                  });
                  setIsSetNetworkForConnectedNodeModalOpen(true);
                }}
              >
                Add to network
              </Button>
            )}
            {" / "}
            {getManagedByText(connectedNodeConnection.managedBySyncRoom)}
          </div>
          <p className="px-3.5 text-slate-400">
            {connectedNodeConnection.connectionName}
          </p>
        </div>
      </div>
    </>
  );
};
