import { Button, ButtonType } from "../../../components/buttons/Button";
import { FaPlus } from "react-icons/fa";
import { Toggle } from "../../../components/buttons/Toggle";
import {
  ConnectionVersion,
  ConnectionVersionStatusEnum,
  Trigger,
  TriggerStatusEnum,
} from "../../../../generated/syncroom-api/src";
import { FaPencil } from "react-icons/fa6";
import { Card } from "../../../components/layout/Card";
import React, { useState } from "react";
import { AddEditTriggerModal } from "./AddEditTriggerModal";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useNodeApi } from "../../../shared/useNodeApi";
import { TriggerActionMenu } from "./TriggerActionMenu";
import { Loader } from "../../../components/Loader";
import { ResponseErrorMessage } from "../../../components/ResponseErrorMessage";

type Props = {
  nodeId: string;
  connectionId: string;
  selectedVersion?: ConnectionVersion;
};

export const Triggers = ({ nodeId, connectionId, selectedVersion }: Props) => {
  const [isAddTriggerModalOpen, setIsAddTriggerModalOpen] = useState(false);
  const [isEditTriggerModalOpen, setIsEditTriggerModalOpen] = useState(false);
  const [selectedTrigger, setSelectedTrigger] = useState<Trigger>();

  const api = useNodeApi();
  const queryClient = useQueryClient();
  const triggersQuery = useQuery({
    queryKey: [
      "nodes",
      nodeId,
      "connections",
      connectionId,
      "versions",
      selectedVersion,
      "triggers",
    ],
    queryFn: () =>
      api.getConnectionTriggers({
        nodeId,
        connectionId,
        versionId: selectedVersion?.id,
      }),
  });

  const updateStatusMutate = useMutation({
    mutationFn: (value: boolean) =>
      api.toggleTriggerStatus({
        nodeId,
        connectionId,
        triggerId: selectedTrigger?.id!,
        versionId: selectedVersion!.id,
        body: value,
      }),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [
          "nodes",
          nodeId,
          "connections",
          connectionId,
          "versions",
          selectedVersion,
          "triggers",
        ],
      }),
  });

  if (triggersQuery.isPending || updateStatusMutate.isPending)
    return <Loader />;
  if (triggersQuery.isError)
    return (
      <ResponseErrorMessage error={triggersQuery.error}>
        Error fetching the triggers
      </ResponseErrorMessage>
    );

  const triggers = triggersQuery.data;
  const isDisabled =
    selectedVersion?.status !== ConnectionVersionStatusEnum.DRAFT;

  const onModalClose = async () => {
    await queryClient.invalidateQueries({
      queryKey: [
        "nodes",
        nodeId,
        "connections",
        connectionId,
        "versions",
        selectedVersion,
        "triggers",
      ],
    });
  };

  return (
    <div>
      {selectedVersion && isAddTriggerModalOpen && (
        <AddEditTriggerModal
          toggleModal={setIsAddTriggerModalOpen}
          nodeId={nodeId}
          connectionId={connectionId}
          versionId={selectedVersion.id}
          onClose={onModalClose}
        />
      )}
      {selectedVersion && isEditTriggerModalOpen && (
        <AddEditTriggerModal
          nodeId={nodeId}
          connectionId={connectionId}
          versionId={selectedVersion.id}
          trigger={selectedTrigger!}
          toggleModal={setIsEditTriggerModalOpen}
          onClose={onModalClose}
        />
      )}
      <Card
        header="Triggers"
        buttons={
          <Button
            iconLeft={<FaPlus />}
            onClick={() => setIsAddTriggerModalOpen(true)}
            disabled={isDisabled}
          >
            Add trigger
          </Button>
        }
      >
        <table>
          <thead>
            <tr>
              <th className="w-1/6" scope="col">
                Entity
              </th>
              <th className="w-fit" scope="col">
                Query
              </th>
              <th className="w-40" scope="col">
                Status
              </th>
              <th className="w-52" scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {triggers.map((trigger, index) => {
              return (
                <tr key={trigger.id ?? index}>
                  <td>{trigger.entityType}</td>
                  <td className="truncate">{trigger.query ?? "-"}</td>
                  <td>
                    <div className="w-full">
                      <Toggle
                        setState={(value) => {
                          setSelectedTrigger(trigger);
                          updateStatusMutate.mutate(value);
                        }}
                        state={trigger.status === TriggerStatusEnum.ACTIVE}
                        disabled={isDisabled}
                      />
                    </div>
                  </td>
                  <td className="flex gap-2">
                    <Button
                      type={ButtonType.TERTIARY}
                      iconLeft={<FaPencil />}
                      onClick={() => {
                        setSelectedTrigger(trigger);
                        setIsEditTriggerModalOpen(true);
                      }}
                      disabled={isDisabled}
                    >
                      Edit
                    </Button>
                    {selectedVersion && (
                      <TriggerActionMenu
                        nodeId={nodeId}
                        connectionId={connectionId}
                        selectedVersionId={selectedVersion.id}
                        isActive={isDisabled}
                        trigger={trigger}
                        onRefresh={() => onModalClose()}
                      />
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </Card>
    </div>
  );
};
