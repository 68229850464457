import { Loader } from "../../../../components/Loader";
import { useNodeApi } from "../../../../shared/useNodeApi";
import { useMutation } from "@tanstack/react-query";
import { ResponseErrorMessage } from "../../../../components/ResponseErrorMessage";
import {
  StartTestRunIncoming200ResponseInner,
  StartTestRunIncomingRequestInner,
  StartTestRunOutgoing200ResponseInner,
  TestRunIssue,
} from "../../../../../generated/syncroom-api/src";
import { useEffect } from "react";

type Props = {
  sourceNodeId: string;
  targetNodeId: string;
  connectionId: string;
  versionId: string;
  issues: TestRunIssue[];
  setOutgoingResults: (
    outgoingResults: StartTestRunOutgoing200ResponseInner[],
  ) => void;
  setIncomingResults: (
    incomingResults: StartTestRunIncoming200ResponseInner[],
  ) => void;
  onProgress: () => void;
};

export const TestRunInProgress = ({
  sourceNodeId,
  targetNodeId,
  connectionId,
  versionId,
  issues,
  setOutgoingResults,
  setIncomingResults,
  onProgress,
}: Props) => {
  const api = useNodeApi();

  const outgoingMutation = useMutation({
    mutationFn: () =>
      api.startTestRunOutgoing({
        nodeId: sourceNodeId,
        connectionId,
        versionId,
        testRunIssue: issues,
      }),
    onSuccess: (data) => {
      setOutgoingResults(data);
      const succeededReplicas: StartTestRunIncomingRequestInner[] = data
        .filter((item) => item.errors === undefined)
        .map((item) => {
          return { issue: item.issue, replica: item.replica! };
        });
      incomingMutation.mutate(succeededReplicas);
    },
  });

  const incomingMutation = useMutation({
    mutationFn: (body: StartTestRunIncomingRequestInner[]) =>
      api.startTestRunIncoming({
        nodeId: targetNodeId,
        connectionId,
        versionId,
        startTestRunIncomingRequestInner: body,
      }),
    onSuccess: (data) => {
      setIncomingResults(data);
      onProgress();
    },
  });

  const { mutate } = outgoingMutation;

  useEffect(() => {
    if (
      !outgoingMutation.isPending &&
      !outgoingMutation.isError &&
      !outgoingMutation.isSuccess
    ) {
      mutate();
    }
  }, [
    outgoingMutation.isPending,
    outgoingMutation.isError,
    outgoingMutation.isSuccess,
    mutate,
  ]);

  if (outgoingMutation.isError)
    return (
      <ResponseErrorMessage error={outgoingMutation.error}>
        Error fetching outgoing test run
      </ResponseErrorMessage>
    );
  if (incomingMutation.isError)
    return (
      <ResponseErrorMessage error={incomingMutation.error}>
        Error fetching outgoing test run
      </ResponseErrorMessage>
    );

  return (
    <div className="flex h-full items-center justify-center">
      <div className="grid justify-items-center">
        <div className="bg-neutral-lesser_grey mb-10 size-40 rounded-full">
          <Loader />
        </div>
        <h1 className="heading-1">Patience, please.</h1>
        <p>We're setting up your test run.</p>
      </div>
    </div>
  );
};
