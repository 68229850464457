/* tslint:disable */
/* eslint-disable */
/**
 * SyncRoom Api
 * SyncRoom API
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@example.net
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * A version of a connection
 * @export
 * @interface ConnectionVersion
 */
export interface ConnectionVersion {
    /**
     * The unique identifier of a connection version in SyncRoom
     * @type {string}
     * @memberof ConnectionVersion
     */
    id: string;
    /**
     * The version name, e.g. "1"
     * @type {string}
     * @memberof ConnectionVersion
     */
    name: string;
    /**
     * The current status of this version
     * @type {string}
     * @memberof ConnectionVersion
     */
    status: ConnectionVersionStatusEnum;
}


/**
 * @export
 */
export const ConnectionVersionStatusEnum = {
    ARCHIVED: 'ARCHIVED',
    ACTIVE: 'ACTIVE',
    DRAFT: 'DRAFT'
} as const;
export type ConnectionVersionStatusEnum = typeof ConnectionVersionStatusEnum[keyof typeof ConnectionVersionStatusEnum];


/**
 * Check if a given object implements the ConnectionVersion interface.
 */
export function instanceOfConnectionVersion(value: object): value is ConnectionVersion {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('status' in value) || value['status'] === undefined) return false;
    return true;
}

export function ConnectionVersionFromJSON(json: any): ConnectionVersion {
    return ConnectionVersionFromJSONTyped(json, false);
}

export function ConnectionVersionFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConnectionVersion {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'status': json['status'],
    };
}

export function ConnectionVersionToJSON(value?: ConnectionVersion | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'name': value['name'],
        'status': value['status'],
    };
}

