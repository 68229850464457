/* tslint:disable */
/* eslint-disable */
/**
 * SyncRoom Api
 * SyncRoom API
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@example.net
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * A representation of an Issue to be used for Test Run
 * @export
 * @interface TestRunIssue
 */
export interface TestRunIssue {
    /**
     * The id of this entity on the ticket management system
     * @type {string}
     * @memberof TestRunIssue
     */
    id: string;
    /**
     * The URN of an issue
     * @type {string}
     * @memberof TestRunIssue
     */
    issueUrn: string;
    /**
     * 
     * @type {string}
     * @memberof TestRunIssue
     */
    entityType: string;
}

/**
 * Check if a given object implements the TestRunIssue interface.
 */
export function instanceOfTestRunIssue(value: object): value is TestRunIssue {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('issueUrn' in value) || value['issueUrn'] === undefined) return false;
    if (!('entityType' in value) || value['entityType'] === undefined) return false;
    return true;
}

export function TestRunIssueFromJSON(json: any): TestRunIssue {
    return TestRunIssueFromJSONTyped(json, false);
}

export function TestRunIssueFromJSONTyped(json: any, ignoreDiscriminator: boolean): TestRunIssue {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'issueUrn': json['issueUrn'],
        'entityType': json['entityType'],
    };
}

export function TestRunIssueToJSON(value?: TestRunIssue | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'issueUrn': value['issueUrn'],
        'entityType': value['entityType'],
    };
}

