import { useEffect, useState } from "react";
import { FaArrowRightLong, FaRightLeft } from "react-icons/fa6";
import { NodeTypeIndicator } from "../../components/node/NodeTypeIndicator";
import { VersionsSelect } from "./subcomponents/VersionsSelect";
import { PageHeader } from "../../components/layout/PageHeader";
import { ConnectionScripts } from "./subcomponents/ConnectionScripts";
import { Triggers } from "./subcomponents/Triggers";
import { useConnectionDetailQuery } from "../useConnectionDetailQuery";
import { Loader } from "../../components/Loader";
import { ConnectionActions } from "./subcomponents/ConnectionActions";
import { Button, ButtonType } from "../../components/buttons/Button";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useIsMutating, useQuery } from "@tanstack/react-query";
import { ResponseErrorMessage } from "../../components/ResponseErrorMessage";
import { useNodeApi } from "../../shared/useNodeApi";

type Props = {
  nodeId: string;
  connectionId: string;
};

export const ConnectionDetail = ({ nodeId, connectionId }: Props) => {
  const api = useNodeApi();
  const [selectedVersionId, setSelectedVersionId] = useState<string>();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const connectionDetailQuery = useConnectionDetailQuery(nodeId, connectionId);

  const versionsQuery = useQuery({
    queryKey: [
      "nodes",
      nodeId,
      "connections",
      connectionId,
      "versions",
      selectedVersionId,
    ],
    queryFn: () =>
      api.getConnectionVersions({
        nodeId,
        connectionId,
      }),
  });

  useEffect(() => {
    if (!selectedVersionId)
      setSelectedVersionId(connectionDetailQuery.data?.activeVersionId);

    if (!connectionDetailQuery.data?.activeVersionId)
      setSelectedVersionId(undefined);

    const version = searchParams.get("version");
    if (version !== null) setSelectedVersionId(version);
  }, [selectedVersionId, connectionDetailQuery.data, searchParams]);

  const isCreatingVersion = useIsMutating({
    mutationKey: ["createVersionMutate"],
  });

  if (
    connectionDetailQuery.isPending ||
    versionsQuery.isPending ||
    isCreatingVersion
  )
    return <Loader />;
  if (connectionDetailQuery.isError)
    return (
      <ResponseErrorMessage error={connectionDetailQuery.error}>
        Error fetching connection details
      </ResponseErrorMessage>
    );
  if (versionsQuery.isError)
    return (
      <ResponseErrorMessage error={versionsQuery.error}>
        Error fetching versions
      </ResponseErrorMessage>
    );

  const connectionDetail = connectionDetailQuery.data;
  if (connectionDetail.activeVersionId && !selectedVersionId) return;

  const selectedVersion = versionsQuery.data.find(
    (version) => version.id === selectedVersionId,
  );

  const selectVersion = (selectedVersionId: string) => {
    setSelectedVersionId(selectedVersionId);
    setSearchParams({ version: selectedVersionId });
  };

  return (
    <>
      <PageHeader grid={true}>
        <div className="flex items-center justify-end gap-6">
          {connectionDetail.activeVersionId && (
            <VersionsSelect
              versions={versionsQuery.data}
              selectedVersionId={selectedVersionId}
              setSelectedVersionId={selectVersion}
              totalErrors={connectionDetail.totalErrors}
            />
          )}
          <ConnectionActions
            nodeId={nodeId}
            connectionDetail={connectionDetail}
            selectedVersion={selectedVersion}
            setSelectedVersionId={selectVersion}
          />
        </div>
        <h1 className="heading-1 col-span-2 flex justify-between">
          <div className="flex items-center gap-2">
            <Button
              iconLeft={<FaRightLeft />}
              type={ButtonType.TERTIARY}
              onClick={() => {
                setSelectedVersionId(undefined);
                navigate(
                  `../connections/${connectionDetail.oppositeConnectionId}`,
                );
              }}
            />
            <NodeTypeIndicator nodeType={connectionDetail.sourceNode.type} />
            {connectionDetail.sourceNode.customName}
            <FaArrowRightLong />
            <NodeTypeIndicator nodeType={connectionDetail.targetNode.type} />
            {connectionDetail.targetNode.customName}
          </div>
        </h1>
      </PageHeader>
      <div className="flex flex-col gap-8">
        <Triggers
          nodeId={nodeId}
          connectionId={connectionId}
          selectedVersion={selectedVersion}
        />
        <ConnectionScripts
          nodeId={nodeId}
          connection={connectionDetail}
          selectedVersion={selectedVersion}
        />
      </div>
    </>
  );
};
